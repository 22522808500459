@value secondary-font, main-dark from "@/styles/variables.module.css";

.button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-family: secondary-font;
  text-transform: uppercase;
}

.chipWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  margin-top: 10px;
}

.chip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border: 1px solid main-dark;
  border-radius: 24px;
  background-color: transparent;
  padding: 6px 12px;
  color: main-dark;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: secondary-font;
}
