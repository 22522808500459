.detailsHead {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  text-transform: uppercase;
}

.detailsHead4 {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

.paragraph {
  margin: 0;
  height: fit-content;
  color: #262626;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  white-space: pre-wrap;
}

.paragraphMargin {
  margin: 0;
  margin: 0 0 0 15px;
  height: fit-content;
  color: #262626;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  white-space: pre-wrap;
}

.paragraphGrey {
  margin: 1rem 0 0 0;
  height: fit-content;
  color: #999999;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  white-space: pre-wrap;
}

.link {
  text-decoration: underline;
}

.link:hover {
  color: #45abff;
}

.gridSubCategories {
  display: grid;
  gap: 1rem;
  margin: 3rem 0;
}

.gridSubCategoriesColumn {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin: 3rem 0;
}

.detailsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 20px 0;
  min-height: 26px;
}
