@value max-small, max-medium, max-large, max-xlarge from "@/styles/variables.module.css";

.heroRecycleItemContainer {
  margin-top: 50px;

  @media max-medium {
    margin-top: 40px;
  }
}

.link {
  font-size: 14px;
  text-decoration: underline;
}

.link:hover {
  cursor: pointer;
}

.recycleHeroCard {
  display: flex;
  flex-direction: column;
  place-content: center;
  background: white;
  padding: 54px 70px;
  width: 750px;
  height: 100%;
}

@media max-xlarge {
  .recycleHeroCard {
    margin: 0 auto;
    width: 100%;
  }
}

@media max-medium {
  .recycleHeroCard {
    padding: 30px;
  }
}

.heroButtonForSaleWrapper {
  width: 179px;
  height: 48px;
  font-weight: 700;
  font-size: 12px;
  font-family: Oxygen, sans-serif;
  letter-spacing: 0.1rem;
}

@media max-small {
  .heroButtonForSaleWrapper {
    width: auto;
  }
}

.heroStatusTag {
  display: inline-block;
  border: 1px solid #000;
  border-radius: 1px;
  background-color: #000;
  padding: 8px 12px;
  color: #fff;
  line-height: 20px;
}

.recyleHeroCardHeader {
  margin-top: 0;
  margin-bottom: 15px;
  color: #000000;
  font-weight: 400;
  font-size: 70px;
  line-height: 68px;
  font-family: 'DMSerifDisplay', serif;
}

@media max-xlarge {
  .recyleHeroCardHeader {
    font-size: 56px;
    line-height: 54px;
  }
}

@media max-large {
  .recyleHeroCardHeader {
    font-size: 44px;
    line-height: 42px;
  }
}

@media max-small {
  .recyleHeroCardHeader {
    margin-bottom: -5px;
    font-size: 26px;
    line-height: 30px;
  }
}

.heroPriceContainer {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 12px;
  font-family: Oxygen, sans-serif;
}

@media max-small {
  .heroPriceContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: auto;
  }
}

.heroPrice {
  color: #000000;
  font-weight: 700;
  font-size: 26px;
  line-height: 80px;
  font-family: Oxygen, sans-serif;
}

@media max-small {
  .heroPrice {
    flex-basis: 100%;
  }
}

.heroOriginalPriceContainer {
  display: flex;
  flex-direction: column;
}

.heroOriginalPriceMessage {
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  font-family: Oxygen, sans-serif;
}

@media max-small {
  .heroOriginalPriceMessage {
    margin-top: -5px;
  }
}

.heroOriginalPrice {
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  font-family: Oxygen, sans-serif;
  text-decoration: line-through;
}

@media max-small {
  .heroOriginalPrice {
    margin-bottom: 10px;
  }
}

.contentRecycleButtons {
  display: none;
}
@media max-medium {
  .contentRecycleButtons {
    display: block;
  }
}

.recycleButtons {
  display: block;
}
@media max-medium {
  .recycleButtons {
    display: none;
  }
}
.bottomContainer {
  display: flex;
  justify-content: space-between;
}
