@value max-small, max-medium, max-large, max-xlarge from "@/styles/variables.module.css";

.buttonWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-width: 600px;

  @media max-medium {
    max-width: 100%;
  }
}

@media max-medium {
  .buttonWrapper {
    flex-direction: column;
  }
}

.socialMediaContainer {
  display: flex;
  position: absolute;
  top: 60px;
  flex-direction: column;
  gap: 0.5rem;
  visibility: hidden;
  z-index: 999999;
  background-color: #ffffff;
}

.socialMediaContainerActive {
  visibility: visible;
}

.buttonSocial {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #000000;
  background-color: #ffffff;
  padding: 15px 18px;
  width: 70px;
}

.socialIcon {
  border-radius: 50%;
  background-position: center;
  background-size: 89%;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
}
