@value padding: 12px;

.categoryContainer {
  position: absolute;
  top: padding;
  left: padding;
}

.favoriteContainer {
  position: absolute;
  top: padding;
  right: padding;
}

.contentContainer {
  display: flex;
  position: absolute;
  bottom: padding;
  left: padding;
  row-gap: 8px;
  flex-direction: column;
  flex-wrap: wrap;
  opacity: 0.9;
  background-color: #ffffff;
  padding: padding;
  width: calc(100% - padding * 2);
}

.title {
  margin: 0;
  color: #000;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  font-family: Oxygen;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

.price {
  color: #000000;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  font-family: Oxygen;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

.designer {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  font-family: Oxygen;
  letter-spacing: 0em;
}
