@value desktop-s, light-peach from "@/styles/variables.module.css";

.heroContainer.heroContainer {
  margin: 0;
  background-color: light-peach;
}

.loadMoreContainer {
  margin-top: 0;
  margin-bottom: 24px;

  @media desktop-s {
    margin-bottom: 28px;
  }
}

.sectionContainer.sectionContainer {
  margin: 28px 0;

  @media desktop-s {
    margin: 48px 0;
  }
}
