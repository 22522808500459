.sliderContainer {
  margin-top: 50px;
}

.head {
  flex-direction: column;
  justify-content: left;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  text-transform: uppercase;
}
