@value max-small, max-medium, max-large, max-xlarge, min-small, min-medium, min-large, min-xlarge from "@/styles/variables.module.css";

.wrapper {
  display: flex;
  justify-content: center;
  gap: 12px;

  @media max-medium {
    flex-direction: column;
  }
}

.wrapper > * {
  flex: 1.5;

  @media max-medium {
    flex: 1;
  }
}

.wrapper > *:last-child {
  display: flex;
  flex: 1;
  gap: 12px;

  @media max-medium {
    flex-direction: column;
  }
}

.obligatoryButton {
  width: 100%;
}
